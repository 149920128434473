// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adults-group-js": () => import("./../../../src/pages/adults-group.js" /* webpackChunkName: "component---src-pages-adults-group-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kids-group-js": () => import("./../../../src/pages/kids-group.js" /* webpackChunkName: "component---src-pages-kids-group-js" */),
  "component---src-pages-loyalty-program-js": () => import("./../../../src/pages/loyalty-program.js" /* webpackChunkName: "component---src-pages-loyalty-program-js" */),
  "component---src-pages-online-group-js": () => import("./../../../src/pages/online-group.js" /* webpackChunkName: "component---src-pages-online-group-js" */),
  "component---src-pages-online-test-js": () => import("./../../../src/pages/online-test.js" /* webpackChunkName: "component---src-pages-online-test-js" */),
  "component---src-pages-other-languages-js": () => import("./../../../src/pages/other-languages.js" /* webpackChunkName: "component---src-pages-other-languages-js" */),
  "component---src-pages-personal-lessons-js": () => import("./../../../src/pages/personal-lessons.js" /* webpackChunkName: "component---src-pages-personal-lessons-js" */)
}

